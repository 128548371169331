<template>
    <a-spin :spinning="spinning">
        <div class="my-md">

            <a-row>
                <a-col :span="24" style="margin-left: 20px">
                    <a-button type="primary" @click="GetTaskName">
                        <a-icon type="edit" />
                        编辑说明
                    </a-button>
                    <a-input-search style="
                            width: 100%;
                            max-width: 250px;
                            margin-bottom: 20px;
                            float: right;
                            margin-right: 30px;
                        " name="filterText" :placeholder="l('SearchWithThreeDot')" @search="search" enterButton
                        v-model="filterText" /></a-col>
            </a-row>
            <a-table ref="table" size="small" :pagination="false" :columns="columns" :data-source="TimedSchedule"
                :rowKey="(tableDatas) => tableDatas.id" :rowSelection="rowSelectionCurrent" :customRow="customRow"
                bordered>
                <template slot="status" slot-scope="text, record">
                    <a-switch checked-children="启用" un-checked-children="禁用" v-model="record.status"
                        @change="EditStatus(record)" />
                </template>
                <template slot="timeZH" slot-scope="text, record">
                    <a @click="GetCorn(record)">修改</a>
                    {{ text }}
                </template></a-table>

            <!-- <a-row>
                <a-col :span="8">
                    <a-row style="color: black;font-weight: 500">审核任务状态超期变更</a-row>
                    <a-row>
                        <a-row class="date">
                            <a-input-number v-model="AuditTaskState.minute" :min="1" />分
                        </a-row>
                    </a-row>
                    <a-row>
                        <a-row class="switch">
                            <a-switch checked-children="已启动" un-checked-children="已关闭"
                                v-model="AuditTaskState.isActivate" />
                        </a-row>
                        <a-row class="tip">
                            设置完成后记得点保存按钮！
                        </a-row>
                    </a-row>
                </a-col>
                <a-col :span="8">
                    <a-row style="color: black;font-weight: 500">每日邮件提醒时间</a-row>
                    <a-row>
                        <a-row class="date">
                            <a-time-picker placeholder="请选择时间" :allowClear="false" format="HH:mm"
                                v-model="DailyMessageDateTime" @change="DailyMessageChange" />
                        </a-row>
                    </a-row>
                    <a-row>
                        <a-row class="switch">
                            <a-switch checked-children="已启动" un-checked-children="已关闭"
                                v-model="DailyMessage.isActivate" />
                        </a-row>
                        <a-row class="tip">
                            设置完成后记得点保存按钮！
                        </a-row>
                    </a-row>
                </a-col>
            </a-row> -->
        </div>
    </a-spin>
</template>

<script>
    import { AppComponentBase } from '../../../../shared/component-base';
    import modalHelper from "@/shared/helpers/modal/modal-helper";
    import {
        AnniversaryManagementSettingsEditDto,
        AttDayAnalysisEditDto,
        AttErrorReportDto,
        BirthdayWishManagementSettingEditDto,
        ClockInReminderMangementSettingEditDto,
        ContractDueRemindSettingEdit,
        EmailSettingsEditDto,
        EmployeeEditDto,
        EmployeeTurnoverEditDto,
        OmitCheckInRemindSettingEditDto,
        SalaryChangeEditDto,
        TenantSettingsEditDto,
        TenantSettingsServiceProxy,
        VerifyOverTimeRemindEditDto,
        ZkDataDockingMangementSettingEditDto,
        IDExpireEditDto,
        ProbationExpireEditDto,
        WFOverTimePersonalEditDto,
        WFOverTimeDepEditDto,
        StaffSelfConfirmationSeetingDto, AuditTaskStateEditDto, DailyMessageEditDto,
    } from '../../../../shared/service-proxies';
    import moment from 'moment';
    import { cloneDeepWith, values } from 'lodash';
    import cron from "@/app/admin/timed-task/cron.vue";
    import edithangfire from "@/app/admin/timed-task/hangfire/edit-hangfire/edit-hangfire.vue";
    import cronstrue from "cronstrue";
    import "cronstrue/locales/zh_CN";
    import { time } from "echarts";

    export default {
        name: 'hangfire',
        mixins: [AppComponentBase],
        data() {
            return {
                TimedScheduleForm: [
                    // {
                    //     id: undefined,
                    //     text: undefined,
                    //     status: undefined,
                    //     remark: undefined,
                    //     timeExpression: undefined,
                    //     timeZH: undefined,
                    //     Job: undefined,
                    // },
                ],
                TimedSchedule: [
                    {
                        id: undefined,
                        text: undefined,
                        status: undefined,
                        remark: undefined,
                        timeExpression: undefined,
                        timeZH: undefined,
                        Job: undefined,
                    },
                ],

                columns: [
                    {
                        title: "状态",
                        dataIndex: "status",
                        width: "50px",
                        align: "center",
                        scopedSlots: { customRender: "status" },
                    },
                    {
                        title: "任务",
                        dataIndex: "text",
                        width: "100px",
                        align: "left",
                        scopedSlots: { customRender: "text" },
                    },

                    {
                        title: "触发规则",
                        dataIndex: "timeZH",
                        width: "220px",
                        align: "left",
                        ellipsis: true,
                        scopedSlots: { customRender: "timeZH" },
                    },

                    {
                        title: "备注",
                        dataIndex: "remark",
                        width: "330px",
                        align: "left",
                    },
                ],

                spinning: false,
                //用户所有设置
                tenantSetting: new TenantSettingsEditDto(),
                BirthdayWishSetting: new BirthdayWishManagementSettingEditDto(),
                AnniversarySettings: new AnniversaryManagementSettingsEditDto(),

                AuditTaskState: new AuditTaskStateEditDto(),
                DailyMessage: new DailyMessageEditDto(),

                ZkDataDockingSettings: new ZkDataDockingMangementSettingEditDto(),
                ClockInReminder: new ClockInReminderMangementSettingEditDto(),
                OmitCheckInRemind: new OmitCheckInRemindSettingEditDto(),
                VerifyOverTimeRemind: new VerifyOverTimeRemindEditDto(),
                ContractDueRemind: new ContractDueRemindSettingEdit(),
                AttDayAnalysisSettings: new AttDayAnalysisEditDto(),
                EmployeeTurnoverSetterings: new EmployeeTurnoverEditDto(),
                AnnualLeaveCalculationSetterings: new AnniversaryManagementSettingsEditDto(),
                SalaryChangeSetterings: new SalaryChangeEditDto(),
                AttMonthAnalysisSetterings: new AttDayAnalysisEditDto(),
                AttErrorReportSetterings: new AttErrorReportDto(),
                IDExpireSetterings: new IDExpireEditDto(),
                ProbationExpireSetterings: new ProbationExpireEditDto(),
                //用来对比是否更新了数据
                OriginalAttErrorReportSetterings: new AttErrorReportDto(),
                WFOverTimePersonal: new WFOverTimePersonalEditDto(),
                WFOverTimeDep: new WFOverTimeDepEditDto(),
                AnnDateTime: undefined,
                BriDateTime: undefined,

                DailyMessageDateTime: undefined,

                OmitCheckInTime1: undefined,
                OmitCheckInTime2: undefined,
                VerifyOverTime1: undefined,
                VerifyOverTime2: undefined,
                AttDayAnalysisTime1: undefined,
                AttDayAnalysisTime2: undefined,
                EmployeeTurnoverTime: undefined,
                AnnualLeaveCalculationTime: undefined,
                SalaryChangeTime: undefined,
                AttMonthAnalysisTime1: undefined,
                AttMonthAnalysisTime2: undefined,
                AttErrorReportStart: undefined,
                AttErrorReportStartHour: undefined,
                IDExpireTime: undefined,
                ProbationExpireTime: undefined,
                WFOverTimePersonalTime: undefined,
                StaffSelfConfirmationSettings: new StaffSelfConfirmationSeetingDto(),
            };
        },
        components: {
            cron,
            edithangfire,
        },
        computed: {
            rowSelectionCurrent() {
                return Object.assign(this.rowSelection, {
                    columnWidth: "20px",
                });
            },
        },
        created() {
            this._tenantSettingServiceProxy = new TenantSettingsServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.getData();
        },
        methods: {

            GetTaskName() {
                if (this.selectedRowKeys.length <= 0) {
                    return abp.message.warn("请选择一项");
                }
                var record = this.selectedRows[0];
                modalHelper
                    .create(
                        edithangfire,
                        { TaskTime: record },
                        {
                            width: "400px",
                        }
                    )
                    .subscribe((res) => {
                        if (res) {
                            for (
                                let i = 0;
                                i < this.TimedScheduleForm.length;
                                i++
                            ) {
                                if (record.id == this.TimedScheduleForm[i].id) {
                                    this.TimedScheduleForm[i] = res;
                                    this.TimedScheduleForm[i].Job.name = res.text;
                                    this.TimedScheduleForm[i].Job.remark =
                                        res.remark;
                                    this.handleSubmit();
                                }
                            }
                        }
                    });
            },
            //将数据整理放到表格中
            GetTable(ID, Settings) {

                console.log(Settings);
                var p = {
                    id: ID,
                    text: Settings.name,
                    timeExpression: Settings.timeExpression,
                    status: Settings.isActivate,
                    remark: Settings.remark,
                    Job: Settings,
                };
                if (Settings.timeExpression) { p.timeZH = cronstrue.toString(Settings.timeExpression, { locale: "zh_CN", }); }
                this.TimedScheduleForm.push(p);
            },
            GetDataTable() {
                for (let i = 0; i < this.TimedScheduleForm.length; i++) {
                    switch (this.TimedScheduleForm[i].id) {
                        // case "AnniversarySettings":
                        //     this.tenantSetting.anniversaryManagement =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "BirthdayWishSetting":
                        //     this.tenantSetting.birthdayWishManagement =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "ZkDataDockingSettings":
                        //     this.tenantSetting.zkDataDockingManagement =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "ClockInReminder":
                        //     this.tenantSetting.clockInReminderMangement =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "OmitCheckInRemind":
                        //     this.tenantSetting.omitCheckInRemindMangement =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "VerifyOverTimeRemind":
                        //     this.tenantSetting.verifyOverTimeRemindMangement =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "ContractDueRemind":
                        //     this.tenantSetting.contractDueRemind =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "AttDayAnalysisSettings":
                        //     this.tenantSetting.attDayAnalysis =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "EmployeeTurnoverSetterings":
                        //     this.tenantSetting.employeeTurnover =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "AnnualLeaveCalculationSetterings":
                        //     this.tenantSetting.annualLeaveCalculation =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "SalaryChangeSetterings":
                        //     this.tenantSetting.salaryChange =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "AttMonthAnalysisSetterings":
                        //     this.tenantSetting.attMonthAnalysis =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "AttErrorReportSetterings":
                        //     this.tenantSetting.attErrorReport =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "IDExpireSetterings":
                        //     this.tenantSetting.idExpire =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "ProbationExpireSetterings":
                        //     this.tenantSetting.probationExpire =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "ProbationExpireSetterings":
                        //     this.tenantSetting.probationExpire =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "WFOverTimePersonal":
                        //     this.tenantSetting.wfOverTimePersonal =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "WFOverTimeDep":
                        //     this.tenantSetting.wfOverTimeDep =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        // case "StaffSelfConfirmationSettings":
                        //     this.tenantSetting.staffSelfConfirmationManagement =
                        //         this.TimedScheduleForm[i].Job;
                        //     break;
                        case "AuditTaskState":
                            this.tenantSetting.auditTaskState =
                                this.TimedScheduleForm[i].Job;
                            break;
                        case "DailyMessage":
                            this.tenantSetting.dailyMessage =
                                this.TimedScheduleForm[i].Job;
                            break;
                    }
                }
            },

            AddData() {
                {
                    // this.GetTable("AnniversarySettings", this.AnniversarySettings);
                    // this.GetTable("BirthdayWishSetting", this.BirthdayWishSetting);
                    // this.GetTable("ZkDataDockingSettings", this.ZkDataDockingSettings);
                    // this.GetTable("ClockInReminder", this.ClockInReminder);
                    // this.GetTable("OmitCheckInRemind", this.OmitCheckInRemind);
                    // this.GetTable("VerifyOverTimeRemind", this.VerifyOverTimeRemind);
                    // this.GetTable("ContractDueRemind", this.ContractDueRemind);
                    // this.GetTable(
                    //     "AttDayAnalysisSettings",
                    //     this.AttDayAnalysisSettings
                    // );
                    // this.GetTable(
                    //     "EmployeeTurnoverSetterings",
                    //     this.EmployeeTurnoverSetterings
                    // );
                    // this.GetTable(
                    //     "AnnualLeaveCalculationSetterings",
                    //     this.AnnualLeaveCalculationSetterings
                    // );
                    // this.GetTable(
                    //     "SalaryChangeSetterings",
                    //     this.SalaryChangeSetterings
                    // );
                    // this.GetTable(
                    //     "AttMonthAnalysisSetterings",
                    //     this.AttMonthAnalysisSetterings
                    // );
                    // this.GetTable(
                    //     "AttErrorReportSetterings",
                    //     this.AttErrorReportSetterings
                    // );
                    // this.GetTable("IDExpireSetterings", this.IDExpireSetterings);
                    // this.GetTable(
                    //     "ProbationExpireSetterings",
                    //     this.ProbationExpireSetterings
                    // );
                    // this.GetTable("WFOverTimePersonal", this.WFOverTimePersonal);
                    // this.GetTable("WFOverTimeDep", this.WFOverTimeDep);
                    // this.GetTable(
                    //     "StaffSelfConfirmationSettings",
                    //     this.StaffSelfConfirmationSettings
                    // );
                    // this.GetTable(
                    //     "SynEmpDataManagementSetting",
                    //     this.SynEmpDataManagementSetting
                    // );

                    // this.GetTable(
                    //     "QuestionPushDto",
                    //     this.QuestionPushDto
                    // );
                }

                this.GetTable(
                    "AuditTaskState",
                    this.AuditTaskState
                );
                this.GetTable(
                    "DailyMessage",
                    this.DailyMessage
                );
                this.TimedSchedule = this.TimedScheduleForm;
                console.log(this.TimedSchedule);
            },
            EditStatus(record) {
                for (let i = 0; i < this.TimedScheduleForm.length; i++) {
                    if (this.TimedScheduleForm[i].id == record.id) {
                        if (record.status) {
                            this.TimedScheduleForm[i].Job.isActivate =
                                record.status;
                            this.handleSubmit();
                        } else {
                            this.TimedScheduleForm[i].Job.isActivate =
                                record.status;
                            this.handleSubmit();
                        }
                    }
                }
            },
            search() {
                // 方法一\
                if (this.filterText) {
                    this.TimedSchedule = this.TimedSchedule.filter(
                        (n) => n.text.indexOf(this.filterText) >= 0
                    );
                } else {
                    this.TimedSchedule = this.TimedScheduleForm;
                }
            },
            ///打开
            GetCorn(Str) {
                modalHelper
                    .create(
                        cron,
                        { timeExpression: Str.timeExpression, Name: Str.text },
                        {
                            width: "1000px",
                        }
                    )
                    .subscribe((res) => {
                        if (res) {
                            for (
                                let i = 0;
                                i < this.TimedScheduleForm.length;
                                i++
                            ) {
                                if (this.TimedScheduleForm[i].id == Str.id) {
                                    this.TimedScheduleForm[i].timeExpression = res;
                                    this.TimedScheduleForm[i].Job.timeExpression =
                                        res;
                                    this.TimedScheduleForm[i].timeZH =
                                        cronstrue.toString(res, {
                                            locale: "zh_CN",
                                        });
                                    this.TimedSchedule = this.TimedScheduleForm;
                                    this.handleSubmit();
                                }
                            }
                        }
                    });
            },

            getData() {
                this.spinning = true;
                this._tenantSettingServiceProxy
                    .getAllSettings()
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then((res) => {

                        this.AuditTaskState = res.auditTaskState;
                        this.DailyMessage = res.dailyMessage;
                        console.log(this.AuditTaskState);
                        console.log(this.DailyMessage);
                        {
                            // this.VerifyOverTimeRemind = res.verifyOverTimeRemindMangement;
                            // this.OmitCheckInRemind = res.omitCheckInRemindMangement;
                            // this.AnniversarySettings = res.anniversaryManagement;



                            // console.log(res);

                            // this.BirthdayWishSetting = res.birthdayWishManagement;
                            // this.AttDayAnalysisSettings = res.attDayAnalysis;
                            // this.EmployeeTurnoverSetterings = res.employeeTurnover;
                            // this.AnnualLeaveCalculationSetterings = res.annualLeaveCalculation;
                            // this.SalaryChangeSetterings = res.salaryChange;
                            // this.AttMonthAnalysisSetterings = res.attMonthAnalysis;
                            // this.AttErrorReportSetterings = cloneDeepWith(res.attErrorReport);
                            // //存原始值
                            // this.OriginalAttErrorReportSetterings = cloneDeepWith(res.attErrorReport);
                            // this.IDExpireSetterings = res.idExpire;
                            // this.ProbationExpireSetterings = res.probationExpire;
                            // this.WFOverTimePersonal = res.wfOverTimePersonal;
                            // this.WFOverTimeDep = res.wfOverTimeDep;
                            // this.ContractDueRemind = res.contractDueRemind;
                        }
                        this.AddData();
                    });
            },

            async handleSubmit() {
                this.GetDataTable();
                this._tenantSettingServiceProxy
                    .updateAllSettings(this.tenantSetting)
                    .finally(() => { })
                    .then((res) => {
                        // this.$notification.success({
                        //     description: undefined,
                        //     message: this.l("SavedSuccessfully"),
                        // });
                        // this.getData();
                    });
            },

            

            /**
             * 每日消息提醒
             * @constructor
             */
            DailyMessageChange(record, str) {
                console.log(str);
                if (str) {
                    let s = str.split(':');
                    this.DailyMessage.hour = parseInt(s[0]);
                    this.DailyMessage.minute = parseInt(s[1]);
                }
            },

            
            async handleSubmit() {
                // this.tenantSetting.anniversaryManagement = this.AnniversarySettings;
                this.tenantSetting.auditTaskState = this.AuditTaskState;
                this.tenantSetting.dailyMessage = this.DailyMessage;
                // this.tenantSetting.birthdayWishManagement = this.BirthdayWishSetting;
                // this.tenantSetting.zkDataDockingManagement = this.ZkDataDockingSettings;
                // this.tenantSetting.clockInReminderMangement = this.ClockInReminder;
                // this.tenantSetting.omitCheckInRemindMangement = this.OmitCheckInRemind;
                // this.tenantSetting.verifyOverTimeRemindMangement = this.VerifyOverTimeRemind;
                // this.tenantSetting.contractDueRemind = this.ContractDueRemind;
                // this.tenantSetting.attDayAnalysis = this.AttDayAnalysisSettings;
                // this.tenantSetting.employeeTurnover = this.EmployeeTurnoverSetterings;
                // this.tenantSetting.annualLeaveCalculation = this.AnnualLeaveCalculationSetterings;
                // this.tenantSetting.salaryChange = this.SalaryChangeSetterings;
                // this.tenantSetting.attMonthAnalysis = this.AttMonthAnalysisSetterings;
                // this.tenantSetting.attErrorReport = this.AttErrorReportSetterings;
                // this.tenantSetting.idExpire = this.IDExpireSetterings;
                // this.tenantSetting.probationExpire = this.ProbationExpireSetterings;
                // this.tenantSetting.wfOverTimePersonal = this.WFOverTimePersonal;
                // this.tenantSetting.wfOverTimeDep = this.WFOverTimeDep;
                // this.tenantSetting.staffSelfConfirmationManagement = this.StaffSelfConfirmationSettings;

                // await this.checkAttErrorReport();
                this.spinning = true;
                this._tenantSettingServiceProxy
                    .updateAllSettings(this.tenantSetting)
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then((res) => {
                        this.$notification.success({
                            description: undefined,
                            message: this.l('SavedSuccessfully'),
                        });
                    });
            },
        },
    };
</script>

<style scoped>
    .date {
        padding: 10px 0px;
    }

    .switch {
        padding: 5px 0px;
    }

    .tip {
        color: #aaa;
    }
</style>