<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="spinning">
            <a-card>
                <a-tabs default-active-key="1" @change="callback">
                    <a-tab-pane key="1" tab="任务调度" forceRender>
                        <hangfire ref="hangfire"></hangfire>
                    </a-tab-pane>
                    <a-button :type="'primary'" @click="handleSubmit" slot="tabBarExtraContent">
                        <a-icon type="save"/>
                        <span>{{l("SaveAll")}}</span>
                    </a-button>
                </a-tabs>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import Hangfire from "./hangfire/hangfire";

    export default {
        name: "timed-task",
        components: {Hangfire},
        mixins:[AppComponentBase],
        data(){
            return{
                zh_CN,
                spinning:false
            }
        },
        created() {

        },
        mounted() {

        },
        methods:{
            handleSubmit(){
                this.$refs.hangfire.handleSubmit();
            },
            callback(v){

            }
        }
    }
</script>

<style scoped>

</style>
