<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <div>编辑</div>
            </div>
        </div>
        <a-row>
            <a-col :span="6"> <q-label :label="l('名称')"> </q-label> </a-col>
            <a-col :span="18"> <a-input v-model="TaskTime.text" /></a-col>
        </a-row>
        <a-row>
            <a-col :span="6">
                <q-label :label="l('备注')"> </q-label>
            </a-col>
            <a-col :span="18">
                <a-textarea
                    :autosize="{ minRows: 3, maxRows: 6 }"
                    v-model="TaskTime.remark"
                />
            </a-col>
        </a-row>
        <!-- <a-row style="margin-top: 10px">
            <a-col :span="6">
                <q-label :label="l('触发规则')"> </q-label>
            </a-col>
            <a-col :span="18"> {{ TaskTime.timeZH }}</a-col>
        </a-row> -->

        <div class="modal-footer">
            <a-button @click="close">
                <a-icon type="close-circle" />
                {{ l("Cancel") }}
            </a-button>
            <a-button :type="'primary'" @click="save">
                <a-icon type="save" />
                保存
            </a-button>
        </div>
    </div>
</template>
<script >
import zh_CN from "ant-design-vue/lib/locale/zh_CN";
import ModalComponentBase from "@/shared/component-base/modal-component-base";
export default {
    name: "edit-hangfire",
    mixins: [ModalComponentBase],
    data() {
        return {
            zh_CN,
            TaskTime: [
                {
                    id: undefined,
                    text: undefined,
                    status: undefined,
                    remark: undefined,
                    timeExpression: undefined,
                    timeZH: undefined,
                    Job: undefined,
                },
            ],
        };
    },
    created() {
        this.fullData();
        // console.log("44444", this.TaskTime);
    },
    mounted() {},
    methods: {
        EditStatus() {
            // console.log("11112321", this.TaskTime);
        },
        save() {
            this.success(this.TaskTime);
        },
    },
};
</script>